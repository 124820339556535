import React from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Container,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "./../style/TopBar.css";
import profilePic from "../public/profile.jpeg";

const tabRoutes = ["/", "/experience", "/projects"];
const tabLabels = ["About", "Experience", "Projects"];

const TopBar = () => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const currentPath = location.pathname;
  const currentTabIndex = tabRoutes.indexOf(currentPath);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const [value, setValue] = React.useState(currentTabIndex);

  return (
    <AppBar position="sticky" className="top-bar">
      <Container>
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          justifyContent={isMobile ? "center" : "space-between"}
          alignItems={isMobile ? "center" : "flex-end"}
          style={{ marginTop: isMobile ? "10px" : "15px" }}
        >
          <Box display="flex" flexDirection="row" className="user-info">
            <img src={profilePic} alt="Tilak Singh" className="profile-pic" />
            <div>
              <div className="user-name">TILAK SINGH</div>
              <div className="user-description">
                <p>
                  Graduate student <strong>@CU Boulder</strong>; Software
                  Engineer
                </p>
              </div>
            </div>
          </Box>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="inherit"
            variant={isMobile ? "fullWidth" : "scrollable"}
            scrollButtons={isMobile ? "auto" : "off"}
            className="tabs"
            indicatorColor="#70b2b9"
          >
            {tabLabels.map((label, index) => (
              <Tab
                key={index}
                label={label}
                component={Link}
                to={tabRoutes[index]}
                className="tab"
                disableRipple
              />
            ))}
          </Tabs>
        </Box>
      </Container>
    </AppBar>
  );
};

export default TopBar;
